import AutomotiveDesignersImage from '@/images/user_types/automotive-designers.png'
import IndustrialDesignersImage from '@/images/user_types/industrial-designers.png'
import InteriorDesignersImage from '@/images/user_types/interior-designers.png'
import ArchitectsImage from '@/images/user_types/architects.png'
import FashionDesignersImage from '@/images/user_types/fashion-designers.png'
import FootwearDesignersImage from '@/images/user_types/footwear-designers.png'
import Image from 'next/image'

const people = [
  {
    name: 'Fashion design',
    role: 'Leverage AI in fashion design by generating endless unique looks from one single sketch. Experiment with colours, textures and materials to create one-of-a-kind collections of clothes and accessories.',
    imageUrl: FashionDesignersImage,
  },
  {
    name: 'Footwear design',
    role: 'Turn your sketches into photo-like, realistic visuals to showcase your concepts. See how your ai-generated sneakers would look in different styles and materials, and facilitate manufacturing.',
    imageUrl: FootwearDesignersImage,
  },
  {
    name: 'Automotive design',
    role: 'Get inspired with innovative design ideas for the cars of the future. Help engineers create the vehicle you’ve always wanted by experimenting on your car rendering sketch with AI.',
    imageUrl: AutomotiveDesignersImage,
  },
  {
    name: 'Industrial design',
    role: 'Create visuals with AI by turning sketches of your projects into clay renderings. Enhance the communication of your ideas to clients and colleagues.',
    imageUrl: IndustrialDesignersImage,
  },
  {
    name: 'Architecture design',
    role: 'Make the costly decision-making process more efficient than ever with AI renders for architecture. From renovations to new builds, generate 3D renderings of your buildings in different variations.',
    imageUrl: ArchitectsImage,
  },
  {
    name: 'Interior & furniture design',
    role: 'Upload a photo of your interior to have AI render your room design with a variety of renovation styles and aesthetics. Perfect for interior designers.',
    imageUrl: InteriorDesignersImage,
  },
]

export function UserTypes() {
  return (
    <div className="bg-white py-24 sm:py-32" id="use-cases">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Accelerate your creative process
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover how to boost your designs and enhance your workflow.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {people.map((person) => (
            <li key={person.name}>
              <Image
                className="aspect-[3/2] w-full rounded-2xl object-cover"
                src={person.imageUrl}
                alt=""
              />

              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {person.role}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
