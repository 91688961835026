import Head from 'next/head'

import { Hero } from '@/components/Hero'
import { LogoCloud } from '@/components/LogoCloud'
import { HowItWorks } from '@/components/HowItWorks'
import { UserTypes } from '@/components/UserTypes'
import { Features } from '@/components/Features'
import { Testimonials } from '@/components/Testimonials'
import { Examples } from '@/components/Examples'
import { CTA } from '@/components/CTA'
import { Faqs } from '@/components/Faqs'
import { Pricing } from '@/components/Pricing'
import { FooterCTA } from '@/components/FooterCTA'
// import { JobsTopList } from '@/components/JobsTopList'
import { Footer } from '@/components/Footer'
import { useEffect } from 'react'

export default function Home() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get('ref')
    if (ref) {
      document.cookie = `lp-ref=${ref}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
    }
  }, [])

  return (
    <>
      <Head>
        <title>Turn Your Drawings Into Images in Seconds – NewArc.ai</title>
        <meta
          property="og:title"
          content="Turn Your Drawings Into Images in Seconds – NewArc.ai"
        />
        <meta
          name="description"
          content="Turn any sketch into a photo with our AI sketch to image generator. Visualise your ideas in realistic photos and 3D renders, ready in seconds!"
        />
        <meta
          name="og:description"
          content="Turn any sketch into a photo with our AI sketch to image generator. Visualise your ideas in realistic photos and 3D renders, ready in seconds!"
        />
        <meta
          property="og:image"
          content="https://newarc.ai/images/og-image.jpg"
        />
      </Head>
      <main>
        <Hero />
        <LogoCloud />
        <HowItWorks />
        <UserTypes />
        <Testimonials />
        <Examples />
        <CTA />
        <Features />
        <Pricing />
        <Faqs />
        {/* <JobsTopList /> */}
        <FooterCTA />
        <Footer />
      </main>
    </>
  )
}
