import Link from 'next/link'
import { BOOK_DEMO_LINK } from './Hero'

// export function FooterCTA() {
//   return (
//     <div className="bg-indigo-100">
//       <div className="px-6 py-20 sm:px-6 lg:px-8">
//         <div className="mx-auto max-w-2xl text-center">
//           <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
//             Unleash your creativity!
//             <br />
//             Try NewArc for free.
//           </h2>
//           {/* <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-900">
//             You get 20 free image generations per month after signing up.
//           </p> */}
//           <div className="mt-10 flex items-center justify-center gap-x-6">
//             <Link
//               href="/api/signup"
//               className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
//             >
//               Get started for free
//             </Link>
//             <Link
//               href={BOOK_DEMO_LINK}
//               className="text-sm font-semibold leading-6 text-gray-900"
//             >
//               <div className="text-sm font-semibold">
//                 Book a demo <span aria-hidden="true">→</span>
//               </div>
//               <div className="text-xs font-medium text-gray-600">
//                 get 50% off first month
//               </div>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

import Image from 'next/image'
import CtaImage from '@/images/cta/footer_cta.png'

export function FooterCTA() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-indigo-100 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          {/* <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg> */}
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ready to Try AI Sketch to Image Generator?
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-900">
              Take your visuals to the next level: transform your sketches and
              flat drawings into original designs and visual concepts.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link
                href="/api/signup"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get started for free
              </Link>
              <Link
                href={BOOK_DEMO_LINK}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                <div className="text-sm font-semibold">
                  Book a demo <span aria-hidden="true">→</span>
                </div>
                <div className="text-xs font-medium text-gray-600">
                  get 50% off first month
                </div>
              </Link>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <Image
              className="absolute left-0 top-0 w-[42.6rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={CtaImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
