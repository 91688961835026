import WeddingDress0Image from '@/images/hero/wedding-dress-0.png'
import WeddingDress1Image from '@/images/hero/wedding-dress-1.jpeg'
import WeddingDress2Image from '@/images/hero/wedding-dress-2.jpeg'
import WeddingDress3Image from '@/images/hero/wedding-dress-3.jpeg'
import Bag0Image from '@/images/hero/bag-0.png'
import Bag1Image from '@/images/hero/bag-1.png'
import Bag2Image from '@/images/hero/bag-2.png'
import Bag3Image from '@/images/hero/bag-3.png'
import Footwear0Image from '@/images/hero/footwear-0.png'
import Footwear1Image from '@/images/hero/footwear-1.png'
import Footwear2Image from '@/images/hero/footwear-2.png'
import Footwear3Image from '@/images/hero/footwear-3.png'
import Image from 'next/image'
import Link from 'next/link'
import { Menu } from '@/components/Menu'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { usePostHog } from 'posthog-js/react'
import { isBrowser } from '@/util/env'

export const BOOK_DEMO_LINK = 'https://cal.com/volchenskov/newarc-ai-demo'

const examples = [
  {
    field: 'Fashion design',
    cases: [
      { type: 'Dress illustration', imageURL: WeddingDress0Image },
      { type: 'AI generated summer dress', imageURL: WeddingDress1Image },
      {
        type: 'AI generated white wedding dress',
        imageURL: WeddingDress2Image,
      },
      { type: 'AI generated yellow dress', imageURL: WeddingDress3Image },
    ],
  },
  {
    field: 'Footwear design',
    cases: [
      { type: 'Sneaker sketch', imageURL: Footwear0Image },
      { type: 'AI generated fashion sneaker', imageURL: Footwear1Image },
      { type: 'AI generated green suede sneaker', imageURL: Footwear2Image },
      {
        type: 'AI generated basketball sneaker',
        imageURL: Footwear3Image,
      },
    ],
  },
  {
    field: 'Accessories design',
    cases: [
      { type: 'Backpack line drawing', imageURL: Bag0Image },
      { type: 'AI generated leather backpack', imageURL: Bag1Image },
      { type: 'AI generated nylon navy backpack', imageURL: Bag2Image },
      { type: 'AI generated tweed backpack', imageURL: Bag3Image },
    ],
  },
]

export function Hero() {
  const [isVideoLoaded, setVideoLoaded] = useState(false)

  const posthog = usePostHog()
  if (isBrowser) {
    ;(window as any).__posthog = posthog
  }
  // useEffect(() => {
  //   posthog.featureFlags.override({ 'lp-tagline': 'test' })
  // }, [])

  const tagLine =
    posthog.getFeatureFlag('lp-tagline') === 'test'
      ? 'Turn your drawings into images in seconds!'
      : 'Turn your drawings into images in seconds'

  useEffect(() => {
    const videoElement = document.querySelector('video')

    if (videoElement) {
      const handleCanPlay = () => {
        setVideoLoaded(true)
      }
      if (videoElement.readyState >= 3) {
        setVideoLoaded(true)
      }

      videoElement.addEventListener('canplay', handleCanPlay)

      // Cleanup
      return () => {
        videoElement.removeEventListener('canplay', handleCanPlay)
      }
    }
  }, [])

  return (
    <div className="isolate bg-white">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <Menu />

      <main>
        <div className="relative pb-24 pt-16 sm:pb-16">
          <div className="mx-auto max-w-7xl px-8 pb-24 lg:flex">
            <div className="mx-auto max-w-2xl text-center lg:mx-0 lg:max-w-md lg:flex-shrink-0 lg:pt-8 lg:text-left">
              <h2 className="text-lg font-medium text-gray-600">
                AI Sketch to Image Generator
              </h2>
              <h1 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {tagLine}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Transform your ideas into realistic images and renders in
                seconds: turn any sketch into a photo using our AI tool. Create
                unlimited designs for yourself or your client – from simple
                sketches on paper to final renders in 3D.
              </p>

              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <Link
                  href="/api/signup"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started for free
                </Link>
                <Link
                  href={BOOK_DEMO_LINK}
                  className="leading-none text-gray-900"
                >
                  <div className="text-sm font-semibold">
                    Book a demo <span aria-hidden="true">→</span>
                  </div>
                  <div className="text-xs font-medium text-gray-600">
                    get 50% off first month
                  </div>
                </Link>
              </div>
            </div>
            <div className="mx-auto mt-14 flex max-w-5xl lg:ml-14 lg:mt-0 lg:items-center">
              <div>
                <style jsx>
                  {`
                    .lds-ring div {
                      box-sizing: border-box;
                      display: block;
                      position: absolute;
                      width: 54px;
                      height: 54px;
                      margin: 8px;
                      border: 8px solid #6366f1;
                      border-radius: 50%;
                      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1)
                        infinite;
                      border-color: #6366f1 transparent transparent transparent;
                    }
                    .lds-ring div:nth-child(1) {
                      animation-delay: -0.45s;
                    }
                    .lds-ring div:nth-child(2) {
                      animation-delay: -0.3s;
                    }
                    .lds-ring div:nth-child(3) {
                      animation-delay: -0.15s;
                    }
                    @keyframes lds-ring {
                      0% {
                        transform: rotate(0deg);
                      }
                      100% {
                        transform: rotate(360deg);
                      }
                    }
                  `}
                </style>
                <div className="relative -m-2 rounded-2xl bg-gray-900/5 p-1 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-3xl lg:p-2">
                  {!isVideoLoaded && (
                    <div className="lds-ring absolute flex h-full w-full items-center justify-center">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                  <video
                    className={`rounded-xl shadow-2xl ring-1 ring-gray-900/10 transition-opacity lg:rounded-2xl ${
                      isVideoLoaded ? 'opacity-100' : 'opacity-0'
                    }`}
                    width={1268}
                    height={720}
                    autoPlay
                    playsInline
                    muted
                    loop
                    preload="auto"
                    src="https://files.newarc.ai/media/hero-video-v3.mp4"
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="divide-y divide-gray-200">
              {examples.map((example, idx) => (
                <div key={idx} className="mx-auto max-w-full py-10">
                  <div className="mb-2">
                    <span className="rounded bg-gray-900/5 p-1 text-xs text-gray-500 ring-1 ring-inset ring-gray-900/10">
                      {example.field}
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-6 md:grid-cols-4 md:gap-8">
                    {example.cases.map((item, idx) => (
                      <div key={idx} className="self-center">
                        <div className="flex grow items-center">
                          <div>
                            <Image
                              className={`h-auto max-w-full rounded-lg ${
                                idx === 0 && 'border-2 border-gray-400'
                              }`}
                              src={item.imageURL}
                              alt={item.type}
                            />
                            <div className="flex-none text-center text-sm text-gray-500">
                              {item.type}
                            </div>
                          </div>
                          {idx === 0 && (
                            <div>
                              <ArrowRightIcon className="w-5 text-gray-400 sm:w-8" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </main>
    </div>
  )
}
