import TadigImage from '@/images/examples/tadig.webp'
import StudioRosadoImage from '@/images/examples/studio-rosado.webp'
import Tmac2kImage from '@/images/examples/tmac2k.png'
import MichaelSturgeImage from '@/images/examples/michael-sturge.jpg'
import Nells18Image from '@/images/examples/nells18.jpeg'
import SpawnofvonImage from '@/images/examples/spawnofvon.jpeg'
import Image from 'next/image'

const examples = [
  {
    title:
      'How To Design Clothes Using AI | GAME CHANGER! 😱 | 3D Clothes Design',
    author: 'Tadii G, YouTube',
    imageUrl: TadigImage,
    postLink: 'https://www.youtube.com/watch?v=SkiPsSmfpSE',
    profileLink: 'https://www.youtube.com/@TadiiG',
  },
  {
    title:
      'AI designed my bomber jacket and I sewed it | Soraya Sewing Tutorial',
    author: 'Studio ROSADO, YouTube',
    imageUrl: StudioRosadoImage,
    postLink: 'https://www.youtube.com/watch?v=uw1jlZcPTcM',
    profileLink: 'https://www.youtube.com/@StudioROSADO',
  },
  {
    title: 'Nike sneakers',
    author: 'Michael Sturge, LinkedIn',
    imageUrl: MichaelSturgeImage,
    postLink:
      'https://www.linkedin.com/feed/update/urn:li:activity:7081707639436447744/',
    profileLink: 'https://www.linkedin.com/in/msturge/',
  },
  {
    title: 'Deep sea creatures sneakers',
    author: 'tmac2k, Instagram',
    imageUrl: Tmac2kImage,
    postLink: 'https://www.instagram.com/p/Czj1nwoRisC/',
    profileLink: 'https://www.instagram.com/tmac2k/',
  },
  {
    title: 'Baja racing EV',
    author: 'nells18, Instagram',
    imageUrl: Nells18Image,
    postLink: 'https://www.instagram.com/p/CuqZtJ3ssjl/',
    profileLink: 'https://www.instagram.com/nells18/',
  },
  {
    title: 'AI and motorcycle sketching',
    author: 'spawnofvon, Instagram',
    imageUrl: SpawnofvonImage,
    postLink: 'https://www.instagram.com/p/Cvhs6mMOdTE/',
    profileLink: 'https://www.instagram.com/spawnofvon/',
  },
  // More examples...
]

export function Examples() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Real designers, real results
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Explore how designers across industries are using our AI sketch to
            image service to turn their sketches into real images.
          </p>
        </div>
        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {examples.map((example) => (
            <li key={example.title}>
              <a
                href={example.postLink}
                className="cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  className="aspect-[3/2] w-full cursor-pointer rounded-2xl object-cover"
                  src={example.imageUrl}
                  alt=""
                />
              </a>
              <h3 className="mt-6 text-lg font-semibold leading-7 tracking-tight text-gray-900">
                <a
                  href={example.postLink}
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                >
                  {example.title}
                </a>
              </h3>
              <p className="text-base leading-7 text-gray-600">
                <a
                  href={example.profileLink}
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                >
                  {example.author}
                </a>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
